import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";

import { signUpWithPlanAction } from "../../../../store/actions/authAction";
import { signUpCollab } from "../../../../store/actions/collabAuthAction";
import loader from "../../../../assets/Images/loader-icon.svg";
import PasswordIcon from "../../../../assets/Images/password.svg";
import PasswordShowIcon from "../../../../assets/Images/password-show.svg";
import Checkmark from "../../../../assets/Images/Checkmark.svg";

const CreatePasswordInput = ({
  setSignInDetail,
  signInDetail,
  clientCollab = false,
  plan = null,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.password) {
      setPasswordCheck({ digit: true, specialChar: true, minimum: true });
    }
  }, [state?.password]);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCheckTerms, setIsCheckTerms] = useState(false);
  const [errors, setErrors] = useState(null);
  const [ip, setIP] = useState("");
  const [passwordCheck, setPasswordCheck] = useState({
    digit: false,
    specialChar: false,
    minimum: false,
  });

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const passwordChange = (e) => {
    const password = e.target.value;
    const regDigit = new RegExp("(?=.*?[0-9])");
    const checkRegDigit = regDigit.test(password);
    const regSpecial = new RegExp("(?=.*?[#?!@$%^&*-])");
    const checkRegSpecial = regSpecial.test(password);
    const regMinimum = new RegExp(".{8,}");
    const checkRegMinimum = regMinimum.test(password);
    setPasswordCheck({
      digit: checkRegDigit,
      specialChar: checkRegSpecial,
      minimum: checkRegMinimum,
    });
    setSignInDetail({ ...signInDetail, password: password });
    setErrors(null);
  };

  const handleContinue = async () => {
    if (clientCollab) {
      setLoading(true);
      const value = {
        email: signInDetail?.email,
        user_name: signInDetail?.name,
        password: signInDetail?.password,
      };
      const data = await signUpCollab(value, navigate);
      if (data?.error) {
        setLoading(false);
        setErrors(data?.error);
        return;
      }
      const updateDetails = {
        ...signInDetail,
        step: signInDetail.step + 1,
        user: data.data,
      };
      setSignInDetail({ ...updateDetails });
      navigate("/client-collab/signin", {
        state: updateDetails,
        replace: true,
      });
    } else {
      setLoading(true);
      const value = {
        email: signInDetail?.email,
        user_name: signInDetail?.name,
        password: signInDetail?.password,
        ip_address: ip,
        // selected_account_plan:
        //   signInDetail?.accountType?.account_type || "individual",
        // selected_payment_type: signInDetail?.plan?.plan_type || "lifetime",
      };

      if (plan) {
        value.selected_payment_type = plan;
      }

      const data = await signUpWithPlanAction(value, navigate);
      if (data?.error) {
        setLoading(false);
        setErrors(data?.error);
        return;
      }
      if (plan === "life_time") {
        window.location.href = `${process.env.REACT_APP_LEMONSQUEEZY_LIFETIME_URL}?checkout[custom][user_id]=${data.data._id}&checkout[email]=${data.data.email}`;
      } else {
        window.location.href = `${process.env.REACT_APP_LEMONSQUEEZY_URL}?checkout[custom][user_id]=${data.data._id}&checkout[email]=${data.data.email}`;
      }
      const updateDetails = {
        ...signInDetail,
        step: signInDetail.step + 1,
        user: data.data,
      };
      setSignInDetail({ ...updateDetails });
      navigate("", {
        state: updateDetails,
        replace: true,
      });
    }
    await setLoading(false);
    return true;
  };

  const handleGoBack = () => {
    setSignInDetail({ ...signInDetail, step: signInDetail.step - 2 });
    navigate("", {
      state: { ...signInDetail, step: signInDetail.step - 2 },
      replace: true,
    });
  };

  const disabled =
    !passwordCheck.digit ||
    !passwordCheck.specialChar ||
    !passwordCheck.minimum ||
    !isCheckTerms;

  return (
    <>
      <div
        className={`onboading-form-content w-full px-6 md:px-0 ${signInDetail.step !==
          4 &&
          clientCollab === false &&
          "hidden"}`}
      >
        <div>
          <p className="text-xs font-semibold lg:text-base text-Neutral800">
            {clientCollab ? "Step 3 of 6" : "Step 4 of 4"}
          </p>
          <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1">
            Create your password
          </h2>
          <p className=" text-Neutral800 font-normal text-xs md:text-base pt-[2px] lg:pt-1">
            Using a complex password keeps your account safe. <br /> Remember to
            use numbers and special characters.
          </p>
        </div>
        <div>
          <div>
            <div class="relative mt-[52px]">
              <input
                class="text-Neutral800 font-medium placeholder:text-Neutral500 p-3 placeholder:text-sm placeholder:font-medium 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded-[8px] border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition max-w-[434px]"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={signInDetail?.password}
                onChange={passwordChange}
              />
              <img
                className="w-full absolute right-[12px] top-[7px] max-w-[32px] cursor-pointer"
                src={showPassword ? PasswordShowIcon : PasswordIcon}
                alt="PasswordIcon"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <div className="mt-6">
              <p
                className={`${
                  passwordCheck?.digit
                    ? "text-AccentMediumDark"
                    : "text-Neutral500"
                }  font-medium text-xs flex items-center`}
              >
                <svg
                  className="mr-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="8"
                    fill={passwordCheck.digit ? "#6D6DF2" : "#DFDFE6"}
                  />
                  <path
                    d="M11.3942 5.71118L6.92199 10.139L4.88916 8.12635"
                    stroke="white"
                    strokeWidth="1.09091"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Contains at least 1 number
              </p>
              <p
                className={`${
                  passwordCheck?.specialChar
                    ? "text-AccentMediumDark"
                    : "text-Neutral500"
                }  font-semibold text-xs flex items-center py-4`}
              >
                <svg
                  className="mr-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="8"
                    fill={passwordCheck.specialChar ? "#6D6DF2" : "#DFDFE6"}
                  />
                  <path
                    d="M11.3942 5.71118L6.92199 10.139L4.88916 8.12635"
                    stroke="white"
                    strokeWidth="1.09091"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Contains at least 1 special character
              </p>
              <p
                className={`${
                  passwordCheck?.minimum
                    ? "text-AccentMediumDark"
                    : "text-Neutral500"
                }  font-semibold text-xs flex items-center`}
              >
                <svg
                  className="mr-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="8"
                    fill={passwordCheck.minimum ? "#6D6DF2" : "#DFDFE6"}
                  />
                  <path
                    d="M11.3942 5.71118L6.92199 10.139L4.88916 8.12635"
                    stroke="white"
                    strokeWidth="1.09091"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Contains at least 8 characters
              </p>
            </div>
          </div>
        </div>
        <div>
          {errors && errors?.length
            ? errors.map((item, index) => {
                return (
                  <p
                    className="mt-1 text-xs font-medium text-Red400 focus-visible:outline-none max-w-auto"
                    key={index}
                  >
                    {item}
                  </p>
                );
              })
            : null}
        </div>
      </div>
      <div className="w-full lg:mt-[20px] mt-[24px] mb-3">
        <div className={`font-semibold text-xs flex items-center pb-6`}>
          <div className="flex items-center filter-checkbox">
            <input
              type="checkbox"
              id="is_terms"
              onChange={(e) => {
                setIsCheckTerms(!isCheckTerms);
              }}
              name="A3-confirmation"
              className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
            />
            <div className="bg-white border-Neutral500 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 transition duration-300">
              {/* <img src={Checkmark} alt="" /> */}
              {isCheckTerms && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 9L10.2812 15.75L7 12.6818"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
          <label htmlFor="is_terms">
            <span className="cursor-default text-Neutral800">
              I agree to the&nbsp;
            </span>
            <a
              href="https://www.clientmanager.io/terms"
              target="_blank"
              rel="noreferrer"
              className="w-[90%] m-auto text-AccentRegular font-bold"
            >
              Terms & Conditions
            </a>
          </label>
        </div>
        <div>
          <button
            type="button"
            className={`uppercase rounded-lg  ${
              disabled
                ? "text-Neutral500 bg-Neutral300"
                : "bg-AccentRegular text-Neutral000 button-hover"
            } font-bold md:h-[46px] h-[60px] p-[0px_24px] lg:mr-4 lg:w-auto w-full`}
            onClick={handleContinue}
            disabled={disabled}
          >
            {loading ? (
              <img src={loader} alt="loader" className="animate-spin" />
            ) : (
              "continue"
            )}
          </button>
          <button
            type="button"
            onClick={handleGoBack}
            disabled={signInDetail.step === 1}
            className="cursor-pointer uppercase transition duration-300 ease-in-out hover:bg-AccentMediumLight rounded-lg text-Neutral900 font-bold h-[46px] bg-Neutral300 p-[0px_24px] lg:inline-block hidden"
          >
            go back
          </button>
        </div>
      </div>
    </>
  );
};

export default CreatePasswordInput;
