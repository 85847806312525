import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

import {
  DeleteAssignTeamMemberClientAction,
  GetClientDetailAction,
} from "../../../store/actions/clientAction";
import { getMemberList } from "../../../store/actions/memberAction";
import AddButton from "../../Buttons/AddButton";
import TeamMemberCard from "../../common/cards/TeamMemberCard";
import SectionTitle from "../../common/typography/SectionTitle";
import AssignTeamMemberModal from "../../Modals/AssignTeamMemberModal";
import CreateMemberModal from "../../Modals/CreateMemberModal";

function TeamMembersSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { clientId } = useParams();

  const clientDetail = useSelector((state) => state.client.clientDetail);
  const member = useSelector((state) => state.member.members);

  const [membersLength, setMembersLength] = useState();
  const [editIsOpen, setEditNotIsOpen] = useState(false);
  const [editMember, setEditMember] = useState(null);
  const [createMemberModal, setCreateMemberModal] = useState(false);

  useEffect(() => {
    if (state) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: state },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientId, dispatch, navigate]);

  useEffect(() => {
    if (!member?.data?.length) {
      dispatch(getMemberList());
    }
  }, [dispatch]);

  useEffect(() => {
    const results = clientDetail?.data?.members?.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });
    setMembersLength(results);
  }, [clientDetail]);

  const handleDelete = ({ memberId, userId }) => {
    if (clientId && memberId) {
      const body = { client: clientId, member: memberId, userId: userId };
      dispatch(DeleteAssignTeamMemberClientAction(body, navigate));
    }
  };

  const mockMembersCards = membersLength?.map((member, idx) => {
    return (
      <TeamMemberCard
        key={member?._id ?? idx}
        member={member}
        onEdit={() => {
          setEditNotIsOpen(true);
          setEditMember(member);
        }}
        onDelete={() =>
          handleDelete({ memberId: member?._id, userId: member?.user._id })
        }
        isEdit={true}
      />
    );
  });

  const handleEditModal = (value) => {
    setEditNotIsOpen(value);
    if (!value) {
      setEditMember(null);
    }
  };

  return (
    <section className="w-100% pt-6 pb-[57px]">
      <div className="flex items-center px-2 mb-5">
        <SectionTitle text="Team Members" />
        {clientDetail?.data?.is_admin === true ? (
          <AddButton title="assign" onClick={() => setEditNotIsOpen(true)} />
        ) : null}
      </div>
      <ul className="flex flex-wrap w-[100%] gap-3 p-0">
        {clientDetail?.isLoading ? (
          <div className="flex flex-wrap gap-3 list-none">
            {[...Array(5)].map((_, idx) => (
              <li className="px-1 pr-4 py-1 list-none rounded-[30px] flex items-center justify-between text-Neutral900 w-[250px] h-[58px] relative skeleton-animation"></li>
            ))}
          </div>
        ) : (
          mockMembersCards
        )}
        {/* <TeamMemberCard /> */}
      </ul>
      <AssignTeamMemberModal
        editIsOpen={editIsOpen}
        setEditNotIsOpen={handleEditModal}
        member={member}
        clientId={clientId}
        editMember={editMember}
        setCreateMemberModal={setCreateMemberModal}
        clientDetail={clientDetail?.data}
        isEdit={true}
      />

      <CreateMemberModal
        isOpen={createMemberModal}
        setModal={setCreateMemberModal}
      />
    </section>
  );
}

export default TeamMembersSection;
