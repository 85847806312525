import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CloseBtn from "../../assets/Images/close-btn.svg";
import loader from "../../assets/Images/loader-icon.svg";
import Colour from "../../assets/json/Colour.json";
import DashboardButton from "../../components/Buttons/DashboardButton";
import TaskLoader from "../../components/ContentLoaders/TaskLoader";
import EmptyProjectCard from "../../components/EmptyProjectCard";
import ArchivedProjectsListModal from "../../components/Modals/ArchivedProjectsListModal";
import NewProjectModal from "../../components/Modals/ProjectModal/NewProjectModal";
import { NewEntityTemplateModal } from "../../components/Modals/Templates/NewEntityTemplateModal";
import { SelectTemplate } from "../../components/Modals/Templates/SelectTemplate";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Pagination from "../../components/Pagination";
import ClientSelect from "../../components/comman/Client/ClientSelect";
import CommonTaskListingNew from "../../components/comman/CommanTaskListingNew";
import Filter from "../../components/filter/filter";
import {
  GetAllProjectsActions,
  GetAllProjectsTasksScreenActions,
  ToggleFavoriteProjectAction,
} from "../../store/actions/projectAction";
import { GetTasksActions } from "../../store/actions/tasksAction";
import FilterIcon from "../../components/Icons/FilterIcon";
import axios from "axios";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const Tasks = () => {
  const titleDropDownRef = useRef(null);
  const dispatch = useDispatch();

  const allTaskList = useSelector((state) => state.tasks.all_tasks);
  const allProjectsList = useSelector((state) => state.project.all_projects);
  const allClients = useSelector((state) => state.client.fullClientList);
  const allTasksScreens = useSelector(
    (state) => state.project.all_tasks_screens
  );

  const [isGeneral, setIsGeneral] = useState("GENERAL");
  // const titleData = ["GENERAL", "MONTHLY", "YEARLY"];
  const [titleData, setTitleData] = useState(["GENERAL", "MONTHLY", "YEARLY"]);
  const [archivedModalIsOpen, setArchivedModalIsOpen] = useState(false);
  const [openTaskList, setOpenTaskList] = useState({});
  const [filterBtn, setFilterBtn] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState({
    status: false,
    id: "",
  });
  const [tasks, setTasks] = useState(
    Object.fromEntries(Object.entries(allTaskList))
  );
  const [addFilter, setAddFilter] = useState({
    urgentonly: [],
    status: [],
    assignedto: [],
    date: [],
    categories: [],
  });
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [projectTemplateModalIsOpen, setProjectTemplateModalIsOpen] =
    useState(false);
  const [selectTemplateModalIsOpen, setSelectTemplateModalIsOpen] =
    useState(false);
  const [selectClientModalIsOpen, setSelectClientModalIsOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectClient, setSelectClient] = useState(null);

  const handleOpenDefaultTemplateModal = () => {
    setProjectTemplateModalIsOpen(false);
    setIsNewProjectModalOpen(true);
  };

  const handleOpenClientModal = () => {
    setProjectTemplateModalIsOpen(false);
    setSelectClientModalIsOpen(true);
  };

  const handleOpenSelectTemplateModal = () => {
    setSelectClientModalIsOpen(false);
    setSelectTemplateModalIsOpen(true);
  };

  useEffect(() => {
    if (filterBtn) {
      document.body.style.overflow = "hidden"; // Disable scroll on body when sidebar is open
    } else {
      document.body.style.overflow = "auto"; // Enable scroll on body when sidebar is closed
    }

    // Cleanup function to reset overflow when the component is unmounted or updated
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [filterBtn]);

  const sortedProjectsByFavourite = useMemo(
    () =>
      allProjectsList.data.docs?.sort((a, b) => {
        return b.is_favourite === a.is_favourite ? 0 : b.is_favourite ? 1 : -1;
      }),
    [allProjectsList.data.docs]
  );

  useEffect(() => {
    if (allProjectsList?.data?.docs?.length) {
      const getUniqueMembers = allProjectsList?.data?.docs?.reduce(
        (acc, curr) => {
          curr?.task_contributors?.forEach((item) => {
            const isDuplicate =
              acc?.findIndex((res) => res._id === item._id) !== -1;
            if (!isDuplicate) {
              acc.push(item);
            } else {
              const index = acc.findIndex((res) => res._id === item._id);
              acc[index] = item;
            }
          });

          return acc;
        },
        []
      );
      setMembers(getUniqueMembers);

      const getUniqueCategories = allProjectsList?.data?.docs?.reduce(
        (acc, curr) => {
          curr?.category?.forEach((item) => {
            const isDuplicate = acc?.findIndex((res) => res === item) !== -1;
            if (!isDuplicate) {
              acc.push(item);
            }
          });

          return acc;
        },
        []
      );
      setCategories(getUniqueCategories);
    }
  }, [allProjectsList]);

  useEffect(() => {
    dispatch(
      GetAllProjectsActions({
        page: page,
        limit: 20,
      })
    );
  }, [dispatch, page]);

  useEffect(() => {
    setTasks(Object.fromEntries(Object.entries(allTaskList)));
  }, [allTaskList]);

  const counting = useCallback(
    (data) => {
      const updateTotalTaskByColumn = (data) => {
        const tempData = data?.reduce((acc, curr) => {
          if (acc[curr?.column]) {
            acc[curr?.column] = acc[curr?.column] + 1;
          } else if (!acc[curr?.column]) {
            acc[curr?.column] = 1;
          } else {
            acc[curr?.column] = 0;
          }
          return acc;
        }, {});
        return tempData;
      };

      const getFilterProjectsTasksLengthData = [];

      Object.entries(data).map(([key, value]) => {
        getFilterProjectsTasksLengthData.push({
          total_tasks_by_column: updateTotalTaskByColumn(value),
          _id: key,
        });
      });

      dispatch({
        type: "GET_FILTER_PROJECT_TASKS_LENGTH_SUCCESS",
        payload: {
          data: getFilterProjectsTasksLengthData,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const getAllData = Object.fromEntries(
      Object.entries(allTaskList).map(([key, value]) => [
        key,
        value.filter((item) => item.type === isGeneral),
      ])
    );

    counting(getAllData);
    setTasks({ ...getAllData });
  }, [isGeneral, allTaskList, counting]);

  useEffect(() => {
    if (!allTasksScreens?.data?.length)
      dispatch(GetAllProjectsTasksScreenActions());
  }, [dispatch]);

  useEffect(() => {
    if (allTasksScreens?.data?.length) {
      const mergedData = [...new Set([...titleData, ...allTasksScreens?.data])];
      setTitleData(mergedData);
    }
  }, [allTasksScreens]);

  useEffect(() => {
    const getFilterData = Object.fromEntries(
      Object.entries(allTaskList).map(([key, value]) => [
        key,
        value.filter((item) => {
          return (
            item?.type === isGeneral &&
            (addFilter.urgentonly?.length
              ? addFilter.urgentonly.includes(item?.priority)
              : true) &&
            (addFilter.status?.length
              ? addFilter.status.includes(item?.tag)
              : true) &&
            (addFilter.assignedto?.length
              ? addFilter?.assignedto?.some((res) =>
                  item?.members?.some((rr) => rr._id === res)
                )
              : true) &&
            (addFilter.categories?.length
              ? addFilter?.categories?.some((res) =>
                  item?.category?.some((rr) => rr === res)
                )
              : true) &&
            (addFilter?.date?.length
              ? (addFilter?.date.includes("Within 7 Days") &&
                  moment()
                    .add(7, "days")
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss") > item?.date &&
                  moment().format("YYYY-MM-DD") < item?.date) ||
                (addFilter?.date.includes("This Month") &&
                  moment().startOf("Month").format("YYYY-MM-DD") <=
                    item?.date &&
                  moment().endOf("Month").format("YYYY-MM-DD") >= item?.date) ||
                (addFilter?.date.includes("After this month") &&
                  moment().add(1, "M").format("YYYY-MM-DD") > item?.date &&
                  moment().endOf("month").format("YYYY-MM-DD") < item?.date)
              : true)
          );
        }),
      ])
    );

    counting(getFilterData);
    setTasks({ ...getFilterData });
  }, [addFilter, allTaskList, counting, isGeneral]);

  const handelSet = () => {
    setIsAnimated(true);
    setTimeout(() => {
      setIsAnimated(false);
      setFilterBtn(!filterBtn);
    }, 350);
  };

  const getTaskList = (client, project) => {
    if (!allTaskList[project]) {
      setIsLoading({ status: true, id: project });
      dispatch(GetTasksActions(client, project, false, setIsLoading));
    }
  };

  const handleToggleFavourite = async (projectId, currentFavouriteStatus) => {
    try {
      // Dispatch the Redux action to toggle the favorite status.
      dispatch(ToggleFavoriteProjectAction(projectId, !currentFavouriteStatus));
      // Optional: Show a success toast or message here.
    } catch (error) {
      // Handle any errors that might occur, such as showing an error toast or message.
      console.error("Failed to toggle favorite:", error);
    }
  };

  return (
    <div>
      <AdminNavbar
        link="/admin/clients/"
        title="Projects & Tasks Dashboard"
        subtitle="Manage your client projects and tasks from this dashboard"
      />
      <Filter
        filterBtn={filterBtn}
        setFilterBtn={setFilterBtn}
        handelSet={handelSet}
        isAnimated={isAnimated}
        setIsAnimated={setIsAnimated}
        addfilter={addFilter}
        setAddFilter={setAddFilter}
        members={members}
        categories={categories}
      />
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
        <div className="pt-5 mb-32 md:mb-10">
          <div className="flex items-center justify-between mb-5">
            <DashboardButton
              title="Add Project"
              onClick={() => setProjectTemplateModalIsOpen(true)}
            />
            <div className="flex flex-col items-end">
              <div className="flex justify-end pb-1">
                <Menu
                  as="div"
                  className="relative inline-block text-left"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="flex items-center">
                    <Menu.Button
                      ref={titleDropDownRef}
                      className="5xl:text-sm text-[12px] mr-[12px] uppercase text-AccentRegular bg-Neutral200 hover:bg-Bg-MediumDark 5xl:h-8 h-7 5xl:w-[120px] w-[88px] flex px-[10px] items-center ml-auto rounded focus-visible:outline-none focus:outline focus:outline-4 focus:outline-Neutral200 font-medium"
                    >
                      <span className="relative">{isGeneral}</span>
                      <svg
                        className="absolute right-[20px]"
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                          fill="currentColor"
                        />
                      </svg>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute z-[999] top-10 right-[-20px] pt-4 pb-2 w-[216px] px-2 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
                      <div className="project-title-divide">
                        <div className="overflow-hidden border-0 h-[100%] overflow-y-auto currancy-scrollbar scrollbar-w-4 relative">
                          {titleData.map((items, index) => {
                            return (
                              <div
                                key={index}
                                className="flex items-center p-2 mt-2 duration-300 rounded cursor-pointer hover:bg-Neutral200 group"
                                onClick={(e) => {
                                  setIsGeneral(items);
                                  setTimeout(() => {
                                    titleDropDownRef?.current?.click();
                                    e.stopPropagation();
                                  }, 0);
                                }}
                              >
                                <p className="w-full text-xs font-normal rounded cursor-pointer text-Neutral800 group-hover:text-AccentRegular">
                                  {items}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Link
                  to="#0"
                  onClick={() => setFilterBtn(true)}
                  className="5xl:text-sm text-xs uppercase text-AccentRegular bg-Neutral200 5xl:h-8 h-7 5xl:w-[120px] w-[88px] flex justify-center gap-1 items-center rounded hover:bg-Bg-MediumDark focus-visible:outline-none focus:outline focus:outline-4 focus:outline-Neutral200 font-medium transition duration-300"
                >
                  <FilterIcon />
                  filter
                </Link>
              </div>
              {allProjectsList?.data?.totalDocs ? (
                <button
                  onClick={() => setArchivedModalIsOpen(true)}
                  className="text-xs text-AccentRegular font-medium inline-block mt-2 tracking-[0.04em] focus-visible:outline-none hover:text-AccentMediumDark"
                >
                  View Archived Projects
                </button>
              ) : null}
            </div>
          </div>

          {allProjectsList?.isLoading && !allProjectsList?.data?.totalDocs ? (
            <TaskLoader />
          ) : allProjectsList?.data?.docs?.length ? (
            sortedProjectsByFavourite
              .filter((item) => tasks[item?._id]?.length > 0)
              .map((projectData, index) => {
                return (
                  <div
                    key={`client-${index}`}
                    className="p-5 mb-8 border rounded-lg shadow-sm"
                  >
                    <div className="flex flex-wrap items-center gap-2 cursor-pointer">
                      <div className="flex items-center">
                        <div
                          className="w-[3%] mr-6 text-AccentRegular"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggleFavourite(
                              projectData._id,
                              projectData?.is_favourite
                            );
                          }}
                        >
                          {projectData?.is_favourite ? (
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 1.075L12.575 6.29166L18.3334 7.13333L14.1667 11.1917L15.15 16.925L10 14.2167L4.85002 16.925L5.83335 11.1917L1.66669 7.13333L7.42502 6.29166L10 1.075Z"
                                fill="currentColor"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 1.075L12.575 6.29167L18.3334 7.13333L14.1667 11.1917L15.15 16.925L10 14.2167L4.85002 16.925L5.83335 11.1917L1.66669 7.13333L7.42502 6.29167L10 1.075Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                        <div className="img mr-[20px] min-w-[75px] min-h-[75px]">
                          {!projectData?.profile &&
                          !projectData?.client?.profile_path ? (
                            <div className="border-Neutral300 w-[75px] h-[75px] flex items-center justify-center rounded-[4px] bg-AccentMediumLight text-Accent focus-visible:outline-none">
                              <span className="text-[30px] 5xl:text-[24px] w-full h-full uppercase flex items-center justify-center rounded-[4px] font-bold">
                                {projectData?.client?.client_name[0]}
                              </span>
                            </div>
                          ) : (
                            <img
                              src={
                                projectData?.client?.profile_path ||
                                projectData?.profile_path
                              }
                              alt="client logo"
                              className="w-[75px] h-[75px] rounded-[4px]"
                            />
                          )}
                        </div>
                        <div className="cursor-pointer meta">
                          <a
                            className="flex flex-col w-full gap-1"
                            href={`/admin/clients/manage/${projectData?.client?._id}`}
                          >
                            <span className="text-AccentRegular text-[10px] bg-Neutral200 rounded-[1.375rem] px-2.5 py-0.5 font-bold uppercase max-w-fit">
                              {projectData?.client?.client_name}
                            </span>
                            <div className="font-bold py-[1px]">
                              <span className="text-neutral-800">
                                {projectData?.title}
                              </span>
                            </div>
                          </a>
                          <div className="flex items-center mt-1">
                            <button
                              className="text-[10px] 5xl:text-[14px] py-1 px-2 text-AccentRegular font-semibold border border-violet-200 rounded flex items-center gap-1 shrink-0"
                              onClick={() => {
                                if (openTaskList[projectData?._id]) {
                                  setOpenTaskList((prev) => ({
                                    ...prev,
                                    [projectData?._id]: false,
                                  }));
                                } else {
                                  setOpenTaskList((prev) => ({
                                    ...prev,
                                    [projectData?._id]: true,
                                  }));
                                  getTaskList(
                                    projectData?.client?._id,
                                    projectData?._id
                                  );
                                }
                              }}
                            >
                              <svg
                                className="w-3 h-3"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                ></path>
                              </svg>
                              <span>All Tasks</span>
                            </button>
                            <a
                              className="text-[10px] 5xl:text-[14px] py-1 px-2 text-AccentRegular rounded flex items-center gap-1 w-full min-w-[100px] 3xl:min-w-[140px]"
                              href={`/admin/clients/manage/${projectData?.client?._id}/project/${projectData?._id}`}
                            >
                              <span>View Project</span>
                              <svg
                                width="13"
                                height="8"
                                viewBox="0 0 13 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.3536 4.35356C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464468C8.97631 0.269205 8.65973 0.269205 8.46447 0.464468C8.2692 0.65973 8.2692 0.976312 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53554C8.65973 7.7308 8.97631 7.7308 9.17157 7.53554L12.3536 4.35356ZM-8.74229e-08 4.5L12 4.5L12 3.5L8.74229e-08 3.5L-8.74229e-08 4.5Z"
                                  fill="var(--accent-color)"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                      {projectData?.total_tasks_by_column && (
                        <div
                          className="flex flex-wrap items-center justify-start flex-grow gap-2 md:justify-end"
                          onClick={() => {
                            if (openTaskList[projectData?._id]) {
                              setOpenTaskList((prev) => ({
                                ...prev,
                                [projectData?._id]: false,
                              }));
                            } else {
                              setOpenTaskList((prev) => ({
                                ...prev,
                                [projectData?._id]: true,
                              }));
                              getTaskList(
                                projectData?.client?._id,
                                projectData?._id
                              );
                            }
                          }}
                        >
                          {Object.entries(
                            projectData?.total_tasks_by_column
                          )?.map(([key, value], index) => {
                            const this_col_color =
                              Colour[
                                projectData?.task_column?.find(
                                  (col) => col.name === key
                                )?.color
                              ];
                            return (
                              <div
                                key={`column-${index}`}
                                className={`px-4 pt-[18px] pb-2 rounded flex-shrink-0 flex flex-col items-start justify-start bg-${this_col_color?.background} flex-grow max-w-[120px] 3xl:max-w-[200px] text-left cursor-pointer`}
                                style={
                                  !this_col_color?.background
                                    ? {
                                        background:
                                          projectData?.task_column?.find(
                                            (col) => col.name === key
                                          )?.color + "20",
                                      }
                                    : null
                                }
                              >
                                <h3
                                  className={`text-3xl font-extrabold text-${this_col_color?.statColor} tracking-tighter leading-5 mb-2`}
                                  style={{
                                    font: "Source Code Pro",
                                    color: !this_col_color?.statColor
                                      ? projectData?.task_column?.find(
                                          (col) => col.name === key
                                        )?.color
                                      : null,
                                  }}
                                >
                                  {value}
                                </h3>
                                <p
                                  className={`opacity-90 text-xs text-${this_col_color?.color} truncate w-[100%] uppercase`}
                                  style={{
                                    font: "Source Code Pro",
                                    color: !this_col_color?.color
                                      ? projectData?.task_column?.find(
                                          (col) => col.name === key
                                        )?.color
                                      : null,
                                  }}
                                >
                                  {key}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    {openTaskList[projectData?._id] &&
                      (isLoading?.status &&
                      isLoading?.id === projectData?._id ? (
                        <div className="flex justify-center mt-10 mb-10">
                          <img
                            src={loader}
                            alt="loader"
                            className="animate-spin relative drop-shadow-Texts z-10 h-[30px]"
                          />
                        </div>
                      ) : (
                        <CommonTaskListingNew
                          clientId={projectData?.client?._id}
                          allTaskList={tasks[projectData?._id] || []}
                          isAll
                          typeIsGeneral={isGeneral}
                          types="task"
                          project={projectData}
                          projectId={projectData?._id}
                          openTaskList={openTaskList}
                        />
                      ))}
                  </div>
                );
              })
          ) : (
            // <div className="text-2xl font-bold text-center">No Task Found</div>
            <EmptyProjectCard
              onClick={() => setProjectTemplateModalIsOpen(true)}
            />
          )}

          {allProjectsList?.data?.docs?.length ? (
            <Pagination
              currantPage={allProjectsList?.data?.page}
              totalData={allProjectsList?.data?.totalDocs}
              hasPrevPage={allProjectsList?.data?.hasPrevPage}
              hasNextPage={allProjectsList?.data?.hasNextPage}
              fromRecord={allProjectsList?.data?.pagingCounter}
              outOfCounter1={allProjectsList?.data?.docs?.length}
              setPage={setPage}
            />
          ) : null}
        </div>
      </div>

      <Modal
        isOpen={selectClientModalIsOpen}
        onRequestClose={() => setSelectClientModalIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="View Form Preview Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-12 pt-8 pb-12 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                  id="modal-title"
                >
                  SELECT CLIENT
                </h3>
                <div className="mb-8">
                  <label className="block mb-2 text-xs font-bold text-Neutral900">
                    Client
                  </label>
                  <ClientSelect
                    allClients={allClients}
                    onChange={(e) => {
                      setSelectClient(e);
                    }}
                  />
                </div>
                <button
                  className={`btn button-hover large-primary-btn text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full min-w-[148px]  p-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out ${
                    !selectClient && "pointer-events-none"
                  }`}
                  onClick={() => {
                    handleOpenSelectTemplateModal();
                  }}
                >
                  <span className="relative z-10 drop-shadow-Texts">
                    Continue to Select Template
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setSelectClientModalIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      {isNewProjectModalOpen && (
        <NewProjectModal
          isOpen={isNewProjectModalOpen}
          setIsOpen={setIsNewProjectModalOpen}
        />
      )}
      {/* {/ View Archived Projects Modal /} */}
      <ArchivedProjectsListModal
        isOpen={archivedModalIsOpen}
        setModal={setArchivedModalIsOpen}
      />

      <NewEntityTemplateModal
        type={"Project"}
        isOpen={projectTemplateModalIsOpen}
        setIsOpen={setProjectTemplateModalIsOpen}
        onCustomTemplateClick={handleOpenClientModal}
        onDefaultTemplateClick={handleOpenDefaultTemplateModal}
      />

      <SelectTemplate
        type={"Project"}
        isOpen={selectTemplateModalIsOpen}
        setIsOpen={setSelectTemplateModalIsOpen}
        clientId={selectClient?._id}
      />
    </div>
  );
};

export default Tasks;
