import React, { useState } from "react";
import { useSelector } from "react-redux";
import CalendarIcon from "../Icons/CalendarIcon";
import GoogleCalendarModal from "../Modals/GoogleCalendarModal";
import GoogleCalendarDetailsModal from "../Modals/GoogleCalendarDetailsModal";
import CheckMarkIcon from "../../assets/Images/green-check-mark.svg";
import googleIcon from "../../assets/Images/google.png";

function LoginWithGoogle({ isSettingPage }) {
  const user = useSelector((state) => state?.auth?.user);

  const [googleCalendarModal, setGoogleCalendarModal] = useState(false);
  const [googleCalendarDetailsModal, setGoogleCalendarDetailsModal] = useState(
    false
  );

  return (
    <div className="text-AccentRegular border border-AccentLight bg-white hover:bg-Neutral300 button-hover w-full min-w-[6rem] h-10 sm:min-w-[11rem] sm:h-11 large-primary-btn text-xs sm:text-sm text-center flex items-center justify-center relative font-semibold py-2 px-[15px] rounded focus-visible:outline focus-visible:outline-[6px] gap-2 focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between py-4 lg:flex-none">
          <div className="flex items-center gap-2">
            {isSettingPage ? (
              <>
                <button
                  type="button"
                  onClick={() => {
                    setGoogleCalendarModal(true);
                  }}
                  className="text-xs text-center inline-block text-AccentRegular relative font-semibold ml-1 rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                >
                  <span className="flex items-center gap-2 justify-center px-6 py-3">
                    <img
                      src={googleIcon}
                      alt="googleIcon"
                      className="w-6 h-6"
                    />
                    <span className="text-sm text-gray-700">Google</span>
                  </span>
                </button>
                {user?.google_calendar_ids?.length > 0 ||
                  (user?.googleAuth && user?.googleAuth?._id && (
                    <img
                      src={CheckMarkIcon}
                      alt="CheckMarkIcon"
                      className="h-[26px] w-[26px] absolute top-[-10px] right-[-10px]"
                    />
                  ))}
              </>
            ) : (
              <>
                {!user?.google_calendar_ids?.length > 0 &&
                  !user?.googleAuth &&
                  !user?.googleAuth?._id && <CalendarIcon />}
                <button
                  type="button"
                  onClick={() => {
                    setGoogleCalendarModal(true);
                  }}
                  className="text-xs text-center inline-block text-AccentRegular relative font-semibold ml-1 rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                >
                  <span className="relative z-10">
                    {user?.google_calendar_ids?.length > 0 ||
                    (user?.googleAuth && user?.googleAuth?._id) ? (
                      <>
                        ✅{" "}
                        <span className="underline">
                          Google Calendar Is Now Integrated
                        </span>
                      </>
                    ) : (
                      <span className="underline">
                        Integrate Google Calendar
                      </span>
                    )}
                  </span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <GoogleCalendarModal
        isOpen={googleCalendarModal}
        setIsOpen={setGoogleCalendarModal}
        setGoogleCalendarDetailsModal={setGoogleCalendarDetailsModal}
        isSettingPage={true}
      />
      <GoogleCalendarDetailsModal
        modalIsOpen={googleCalendarDetailsModal}
        setIsOpen={setGoogleCalendarDetailsModal}
      />
    </div>
  );
}

export default LoginWithGoogle;
