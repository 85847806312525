import axios from "axios";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

export const initQuestionnaire = (value, setIsOpen, navigate, setErrors) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/questionnaire/init-questionnaire",
        { ...value }
      );
      setIsOpen(false);
      if (value?.lead) {
        navigate(
          `/admin/leads/manage/${value?.lead}/questionnaire/${data.data?._id}`
        );
      } else {
        navigate(
          `/admin/clients/manage/${value?.client_id}/questionnaire/${data.data?._id}`
        );
      }
      toast.success("Questionnaire created successfully");
      return dispatch({
        type: "CREATE_QUESTIONNAIRE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        if (setErrors) setErrors(err);
        return dispatch({
          type: "CLIENT_CREATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_QUESTIONNAIRE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CreateQuestion = (
  value,
  setSubmitting,
  setErrors,
  setIsOpenQuestionnaire
) => {
  return async (dispatch) => {
    try {
      await axios.post("api/v1/questionnaire/add-new-question", {
        ...value,
      });
      setSubmitting(false);
      setIsOpenQuestionnaire(false);
      await dispatch(GetQuestionnaireOfClient(value.id));
      toast.success("Question created successfully");
      return dispatch({
        type: "CREATE_QUESTION_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "CLIENT_CREATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "CREATE_QUESTION_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateQuestionnaireForm = (
  value,
  setSubmitting,
  setErrors,
  refreshNeed,
  setIsEditCategory
) => {
  return async (dispatch) => {
    try {
      await axios.put("api/v1/questionnaire/update-questionnaire", value);
      if (refreshNeed) {
        await dispatch(GetQuestionnaireOfClient(value.id));
      }
      setSubmitting(false);
      setIsEditCategory(false);
      if (refreshNeed) {
        toast.success("Questionnaire Saved successfully");
      }
      return dispatch({
        type: "UPDATE_QUESTIONNAIRE_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "UPDATE_QUESTIONNAIRE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "UPDATE_QUESTIONNAIRE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateQuestionOfQuestionnaire = (
  value,
  setSubmitting,
  setErrors
) => {
  return async (dispatch) => {
    try {
      await axios.post("api/v1/questionnaire/update-question", value);
      await dispatch(GetQuestionnaireOfClient(value.questionnaire_id));
      setSubmitting(false);
      toast.success("Question updated successfully");
      return dispatch({
        type: "UPDATE_QUESTION_OF_QUESTIONNAIRE_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "UPDATE_QUESTION_OF_QUESTIONNAIRE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "UPDATE_QUESTION_OF_QUESTIONNAIRE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetQuestionnaireOfClient = (id, openModal) => {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: "SET_QUESTIONNAIRE",
      //   payload: { isLoading: true },
      // });
      let { data } = await axios.get(
        `api/v1/questionnaire/get-questionnaire-client-id?id=${id}`
      );
      if (openModal) {
        openModal(true);
      }
      return dispatch({
        type: "SET_QUESTIONNAIRE",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "SET_QUESTIONNAIRE_ERROR",
        payload: error,
      });
    }
  };
};

export const GetQuestionnaireOfClientId = (client) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/questionnaire/get-questionnaire-by-client-id?id=${client}`
      );
      return dispatch({
        type: "SET_QUESTIONNAIRE_OF_CLIENT",
        payload: { isLoading: false, data: data.data, client: client },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "SET_QUESTIONNAIRE_ERROR",
        payload: error,
      });
    }
  };
};

export const GetQuestionnaireByKey = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_OPEN_QUESTIONNAIRE",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(
        `api/v1/questionnaire/get-questionnaire-by-key?id=${id}`
      );
      return dispatch({
        type: "SET_OPEN_QUESTIONNAIRE",
        payload: {
          isLoading: false,
          data: data.data,
          brandIdentity: data.brandIdentity,
          customDomain: data.customDomain,
          customEmail: data.customEmail,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        return dispatch({
          type: "SET_OPEN_QUESTIONNAIRE_ERROR",
          payload: {
            error: true,
            error_message: "Questionnaire Key is not valid",
            isLoading: false,
          },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_OPEN_QUESTIONNAIRE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const SaveAnswers = (value, setSubmitting, setAnswers, setCloseTab) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append("id", value._id);
      formData.append("questions", JSON.stringify(value.answers));
      value.answers.forEach((item, index) => {
        if (item?.selectedFiles) {
          item.selectedFiles.forEach((subItem) => {
            formData.append(item._id, subItem);
          });
          item.selectedFiles = undefined;
        }
      });
      let { data } = await axios.put(
        "api/v1/questionnaire/save-questionnaires-answer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      setSubmitting(false);
      setAnswers([]);
      dispatch({
        type: "SET_OPEN_QUESTIONNAIRE",
        payload: { isLoading: false, data: data.data },
      });
      toast.success("THANKS FOR YOUR ANSWERS - THEY'VE JUST BEEN SENT");
      setCloseTab(true);
      return dispatch({
        type: "SAVE_ANSWER_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        return dispatch({
          type: "SAVE_ANSWER_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "SAVE_ANSWER_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const SaveFormConfig = (value, setCopyStatus) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        "api/v1/questionnaire/update-questionnaire",
        value
      );
      copy(`${window.location.origin}/request-info/${value.id}`);
      setCopyStatus("Copied");
      setTimeout(() => {
        setCopyStatus("Copy Link");
      }, 1000);
      dispatch({
        type: "SET_QUESTIONNAIRE",
        payload: { isLoading: false, data: data.data },
      });
      return dispatch({
        type: "SAVE_FORM_CONFIG_SUCCESS",
        payload: {},
      });
    } catch (error) {
      setCopyStatus("Something went wrong try again.");
      setTimeout(() => {
        setCopyStatus("Copy Link");
      }, 2000);
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "SAVE_FORM_CONFIG_ERROR",
        payload: error,
      });
    }
  };
};

export const DeleteQustions = (questionID, questionnaireID, setLoading) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/questionnaire/delete-question`,
        {
          id: questionnaireID,
          question_id: questionID,
        }
      );
      setLoading(false);
      toast.success("Question Deleted successfully");
      dispatch({
        type: "SET_QUESTIONNAIRE",
        payload: { isLoading: false, data: data.data },
      });
      return dispatch({
        type: "QUESTOINS_DELETE_SUCCESS",
        payload: {},
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
      return dispatch({
        type: "QUESTOINS_DELETE_ERROR",
        payload: error,
      });
    }
  };
};

export const DeleteQuestionnaire = (questionnaireID, setLoading) => {
  return async (dispatch) => {
    try {
      await axios.delete(
        `api/v1/questionnaire/delete-questionnaire/${questionnaireID}`
      );
      setLoading({
        status: false,
        id: "",
      });
      toast.success("Questionnaire Deleted successfully");
      return dispatch({
        type: "QUESTIONNAIRE_DELETE_SUCCESS",
        payload: { isLoading: false, id: questionnaireID },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading({
        status: false,
        id: "",
      });
      return dispatch({
        type: "QUESTIONNAIRE_DELETE_ERROR",
        payload: error,
      });
    }
  };
};
