import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import LoginWithGoogle from "../Settings/LoginWithGoogle";
import InvoiceConnectionBtn from "../Buttons/InvoiceConnectionBtn";
import { useSelector } from "react-redux";
import StripeIcon from "../../assets/Images/stripe-logo.svg";

function Connect() {
  const user = useSelector((state) => state.auth.user);

  const [loadingStripeLink, setLoadingStripeLink] = useState(false);
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);

  return (
    <Tab.Panel className="5xl:px-[96px] px-6 md:px-0 w-full pt-0">
      <div className="flex gap-5 items-center">
        <div className="mt-5 5xl:max-w-[500px] md:max-w-[300px] hover:shadow-md transition-all duration-300 hover:border-gray-300 hover:bg-gray-50">
          <LoginWithGoogle  isSettingPage={true} />
        </div>
        <div className="mt-5 5xl:max-w-[500px] md:max-w-[300px] hover:shadow-md transition-all duration-300 hover:border-gray-300 hover:bg-gray-50">
          <InvoiceConnectionBtn
            onClick={() => {
              if (window) {
                const url = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${
                  process.env.REACT_APP_PUBLIC_STRIPE_OAUTH_CLIENT_ID
                }&scope=read_write&state=${Math.random() * 100}&return_url=${
                  process.env.REACT_APP_PUBLIC_BASE_URL
                }`;
                window.document.location.href = url;
              }
            }}
            isCheck={user?.stripeInfo}
            text={loadingStripeLink ? "Connecting..." : "Connect"}
            logo={StripeIcon}
            type="stripe"
            isSettingPage={true}
          />
        </div>
        <div className="mt-5 5xl:max-w-[500px] md:max-w-[300px] hover:shadow-md transition-all duration-300 hover:border-gray-300 hover:bg-gray-50">
          <InvoiceConnectionBtn
            onClick={() => {
              if (window) {
                const url = `${process.env.REACT_APP_PAYPAL_BASE_URL}/signin/authorize?flowEntry=static&client_id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&response_type=code&scope=openid profile email&redirect_uri=${process.env.REACT_APP_PUBLIC_BASE_URL}`;
                window.document.location.href = url;
              }
            }}
            isCheck={user.payPalInfo?.code || user.payPalInfo?.refresh_token}
            text={loadingPaymentLink ? "Connecting..." : "Connect"}
            isSettingPage={true}
            // logo={<PaypalIcon />}
          />
        </div>
      </div>
    </Tab.Panel>
  );
}

export default Connect;
